<style scoped src="./index.less" lang="less" rel="stylesheet/less"></style>
<template src="./index.html"></template>
<script>
import mixinPage from "../../libs/mixinPage";
export default {
  mixins: [mixinPage],
  components: {},
  name: "",
  data() {
    return {
      config:{
          title:"送餐机器人一个顶10人 厦门餐饮业智能化突显",
          source:"来源：厦门晚报报道 2019-09-26",
      }
    };
  },
  methods: {
    
  },
  mounted() {

  },
  activated() {
  }
};
</script>



